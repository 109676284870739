import React from "react";
import { withRouter } from "react-router-dom";
import campaignService from "../services";
import QuestionViewer from "./QuestionViewer";
import CampaignViewer from "./CampaignQuestionsViewer";
import OTPViewer from "./OTPViewer";
import CustomerInfoViewer from "./CustomerInfoViewer";
import Notifications from "../../utils/notifications";
import CampaignQuestionsViewer from "./CampaignQuestionsViewer";
import SubmissionModal from "./SubmissionModal";
import Spinner from "react-bootstrap/Spinner";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

class CampaignPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      phone: "",
      age: "",
      profession: "",
      campaign: null,
      answersList: [],
      showOTPViewer: true,
      showInfoFormViewer: false,
      showCampaignViewer: false,
      showSubmissionModal: false,
      outletCode:
        localStorage.getItem("_jti_outletCode") === null
          ? ""
          : localStorage.getItem("_jti_outletCode"),
      outletName:
        localStorage.getItem("_jti_outletName") === null
          ? ""
          : localStorage.getItem("_jti_outletName"),
      submittingSurvey: false,
      showSurveyCountModal: false,
    };
  }

  getLiveCampaign = () => {
    campaignService.getLiveCampaign().then(
      (response) => {
        let answers = this.state.answersList;
        for (var i = 0; i < response.data.schema.length; i++) {
          answers.push("");
        }
        localStorage.setItem("campaignID", response.data.id);
        localStorage.setItem("campaignName", response.data.name);
        this.handleShowSurveyDetails();
        this.setState({
          campaign: response.data,
          answersList: [...answers],
        });
      },
      (error) => {}
    );
  };

  handleShowSurveyDetails = () => {
    campaignService.getSurveyDetails(localStorage.getItem("campaignID")).then(
      (response) => {
        localStorage.setItem("surveyCount", response.data.count);
        this.setState({ showSurveyCountModal: true });
      },
      (err) => {}
    );
  };

  onChangeAnswer = (index, answer) => {
    let answers = this.state.answersList;
    answers[index] = answer;
    this.setState({
      answersList: [...answers],
    });
  };

  componentDidMount() {
    this.getLiveCampaign();
  }

  validateSchema = () => {
    const answers = this.state.answersList;
    if (!this.state.name) {
      Notifications.error("Name is required");
      return false;
    }
    if (!this.state.phone) {
      Notifications.error("Phone is required");
      return false;
    }
    for (var i = 0; i < answers.length; i++) {
      if (!answers[i]) {
        Notifications.error("Question #" + (i + 1) + " answer is empty");
        return false;
      }
    }
    return true;
  };

  prepareAnswer = (isSignature) => {
    let response = [];
    this.state.answersList.map((item) => {
      response.push({
        answer: item.answer === "" ? "Not Answered" : item.answer,
        question: item.question,
      });
    });

    return {
      name: this.state.name,
      phone: this.state.phone,
      age: this.state.age,
      profession: this.state.profession,
      campaign: this.state.campaign.id,
      response: response,
      outlet_id: this.state.outletCode,
      outlet_name: this.state.outletName,
      signature: isSignature,
    };
  };

  entryData = (id, data) => {
    this.setState({ submittingSurvey: true });
    campaignService.createEntry(id, data).then(
      (response) => {
        console.log(response.data);
        Notifications.success("Response recorded successfully");
        if(response.data.user_exists) {
          Notifications.alert("This EAS has been contacted before.");
        }
        this.setState({ submittingSurvey: true });
        this.setState(
          {
            name: "",
            phone: "",
            age: "",
            profession: "",
            campaign: null,
            answersList: [],
            showOTPViewer: true,
            showInfoFormViewer: false,
            showCampaignViewer: false,
            showSubmissionModal: false,
          },
          () => {
            this.setState({ submittingSurvey: false });
            this.getLiveCampaign();
          }
        );
      },
      (err) => {}
    );
  };

  onSubmit = (isSignature) => {
    this.setState({ showSubmissionModal: false });
    if (true) {
      const data = this.prepareAnswer(isSignature);
      this.entryData(this.state.campaign.id, data);
    }
  };

  handleOTPViewer = (data) => {
    this.setState({
      showOTPViewer: false,
      showInfoFormViewer: true,
      phone: data.phone,
      outletCode: data.outletCode,
      outletName: data.outletName,
    });
  };

  handleCustomerInfoViewer = (data) => {
    this.setState({
      showInfoFormViewer: false,
      showCampaignViewer: true,
      name: data.name,
      age: data.age,
      profession: data.profession,
    });
  };

  handleCampaignQuestionsViewer = (data) => {
    this.setState({
      answersList: [...data.response],
      showSubmissionModal: true,
    });
  };

  handleClosingCallbackFromSubmissionModal = () =>
    this.setState({ showSubmissionModal: false });

  handleLeftArrow = () => {
    if (this.state.showCampaignViewer) {
      this.setState({ showCampaignViewer: false, showInfoFormViewer: true });
    } else if (this.state.showInfoFormViewer) {
      this.setState({ showInfoFormViewer: false, showOTPViewer: true });
    }
  };

  handleRightArrow = () => {};

  render() {
    var today = new Date();
    var date =
      today.getDate() +
      " " +
      monthNames[today.getMonth()] +
      " " +
      today.getFullYear();
    if (!this.state.campaign || this.state.submittingSurvey) {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
          {this.state.submittingSurvey ? <h4>Submitting</h4> : <h4>Loading</h4>}
        </div>
      );
    }
    return (
      <div className={"row form-group"}>
        {this.state.showOTPViewer && (
          <OTPViewer
            campaign={this.state.campaign}
            callback={this.handleOTPViewer}
            outletCode={this.state.outletCode}
            outletName={this.state.outletName}
            phone={this.state.phone}
          />
        )}
        {this.state.showCampaignViewer && (
          <CampaignQuestionsViewer
            campaignName={this.state.campaign.name}
            campaign={this.state.campaign}
            answersList={this.state.answersList}
            callback={this.handleCampaignQuestionsViewer}
          />
        )}
        {this.state.showInfoFormViewer && (
          <CustomerInfoViewer
            campaignName={this.state.campaign.name}
            callback={this.handleCustomerInfoViewer}
            info={this.state}
          />
        )}
        <SubmissionModal
          submissionReview={this.state}
          show={this.state.showSubmissionModal}
          successCallback={this.onSubmit}
          closingCallback={this.handleClosingCallbackFromSubmissionModal}
        />

        {/*/survey count Modal*/}
        <Modal
          show={this.state.showSurveyCountModal}
          onHide={() => this.setState({ showSurveyCountModal: false })}
          dialogClassName="modal-90w"
          style={{ borderRadius: 0 }}
        >
          <Modal.Header
            style={{
              borderBottom: "none",
              paddingBottom: 0,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <h3 style={{ fontWeight: 400 }}> 📄 Survey Progress </h3>
          </Modal.Header>
          <Modal.Body>
            {this.state.surveyDetails === null ? (
              <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
              </Spinner>
            ) : (
              <div>
                <h5>{date}</h5>
                <h5 style={{ fontWeight: 100 }}>
                  Till now you have achieved{" "}
                  <strong style={{ color: "red" }}>
                    {localStorage.getItem("surveyCount")}
                  </strong>{" "}
                  survey
                </h5>
              </div>
            )}
          </Modal.Body>
          <Modal.Footer style={{ borderTop: "none" }}>
            <Button
              variant="secondary"
              onClick={() => this.setState({ showSurveyCountModal: false })}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default withRouter(CampaignPage);
